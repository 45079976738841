exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contribute-js": () => import("./../../../src/pages/contribute.js" /* webpackChunkName: "component---src-pages-contribute-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-methods-mdx-frontmatter-slug-js-content-file-path-methods-abtesting-mdx": () => import("./../../../src/pages/methods/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/methods/abtesting.mdx" /* webpackChunkName: "component---src-pages-methods-mdx-frontmatter-slug-js-content-file-path-methods-abtesting-mdx" */),
  "component---src-pages-methods-mdx-frontmatter-slug-js-content-file-path-methods-cardsorting-mdx": () => import("./../../../src/pages/methods/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/methods/cardsorting.mdx" /* webpackChunkName: "component---src-pages-methods-mdx-frontmatter-slug-js-content-file-path-methods-cardsorting-mdx" */),
  "component---src-pages-methods-mdx-frontmatter-slug-js-content-file-path-methods-concepttesting-mdx": () => import("./../../../src/pages/methods/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/methods/concepttesting.mdx" /* webpackChunkName: "component---src-pages-methods-mdx-frontmatter-slug-js-content-file-path-methods-concepttesting-mdx" */),
  "component---src-pages-methods-mdx-frontmatter-slug-js-content-file-path-methods-contextualinquiry-mdx": () => import("./../../../src/pages/methods/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/methods/contextualinquiry.mdx" /* webpackChunkName: "component---src-pages-methods-mdx-frontmatter-slug-js-content-file-path-methods-contextualinquiry-mdx" */),
  "component---src-pages-methods-mdx-frontmatter-slug-js-content-file-path-methods-desirabilitystudy-mdx": () => import("./../../../src/pages/methods/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/methods/desirabilitystudy.mdx" /* webpackChunkName: "component---src-pages-methods-mdx-frontmatter-slug-js-content-file-path-methods-desirabilitystudy-mdx" */),
  "component---src-pages-methods-mdx-frontmatter-slug-js-content-file-path-methods-diarystudy-mdx": () => import("./../../../src/pages/methods/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/methods/diarystudy.mdx" /* webpackChunkName: "component---src-pages-methods-mdx-frontmatter-slug-js-content-file-path-methods-diarystudy-mdx" */),
  "component---src-pages-methods-mdx-frontmatter-slug-js-content-file-path-methods-eyetracking-mdx": () => import("./../../../src/pages/methods/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/methods/eyetracking.mdx" /* webpackChunkName: "component---src-pages-methods-mdx-frontmatter-slug-js-content-file-path-methods-eyetracking-mdx" */),
  "component---src-pages-methods-mdx-frontmatter-slug-js-content-file-path-methods-fieldstudy-mdx": () => import("./../../../src/pages/methods/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/methods/fieldstudy.mdx" /* webpackChunkName: "component---src-pages-methods-mdx-frontmatter-slug-js-content-file-path-methods-fieldstudy-mdx" */),
  "component---src-pages-methods-mdx-frontmatter-slug-js-content-file-path-methods-firstclicktest-mdx": () => import("./../../../src/pages/methods/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/methods/firstclicktest.mdx" /* webpackChunkName: "component---src-pages-methods-mdx-frontmatter-slug-js-content-file-path-methods-firstclicktest-mdx" */),
  "component---src-pages-methods-mdx-frontmatter-slug-js-content-file-path-methods-fivesecondtest-mdx": () => import("./../../../src/pages/methods/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/methods/fivesecondtest.mdx" /* webpackChunkName: "component---src-pages-methods-mdx-frontmatter-slug-js-content-file-path-methods-fivesecondtest-mdx" */),
  "component---src-pages-methods-mdx-frontmatter-slug-js-content-file-path-methods-focusgroup-mdx": () => import("./../../../src/pages/methods/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/methods/focusgroup.mdx" /* webpackChunkName: "component---src-pages-methods-mdx-frontmatter-slug-js-content-file-path-methods-focusgroup-mdx" */),
  "component---src-pages-methods-mdx-frontmatter-slug-js-content-file-path-methods-intercepttesting-mdx": () => import("./../../../src/pages/methods/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/methods/intercepttesting.mdx" /* webpackChunkName: "component---src-pages-methods-mdx-frontmatter-slug-js-content-file-path-methods-intercepttesting-mdx" */),
  "component---src-pages-methods-mdx-frontmatter-slug-js-content-file-path-methods-moderatedusabilitytesting-mdx": () => import("./../../../src/pages/methods/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/methods/moderatedusabilitytesting.mdx" /* webpackChunkName: "component---src-pages-methods-mdx-frontmatter-slug-js-content-file-path-methods-moderatedusabilitytesting-mdx" */),
  "component---src-pages-methods-mdx-frontmatter-slug-js-content-file-path-methods-multivariatetest-mdx": () => import("./../../../src/pages/methods/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/methods/multivariatetest.mdx" /* webpackChunkName: "component---src-pages-methods-mdx-frontmatter-slug-js-content-file-path-methods-multivariatetest-mdx" */),
  "component---src-pages-methods-mdx-frontmatter-slug-js-content-file-path-methods-participatorydesign-mdx": () => import("./../../../src/pages/methods/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/methods/participatorydesign.mdx" /* webpackChunkName: "component---src-pages-methods-mdx-frontmatter-slug-js-content-file-path-methods-participatorydesign-mdx" */),
  "component---src-pages-methods-mdx-frontmatter-slug-js-content-file-path-methods-preferencetesting-mdx": () => import("./../../../src/pages/methods/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/methods/preferencetesting.mdx" /* webpackChunkName: "component---src-pages-methods-mdx-frontmatter-slug-js-content-file-path-methods-preferencetesting-mdx" */),
  "component---src-pages-methods-mdx-frontmatter-slug-js-content-file-path-methods-prototypetesting-mdx": () => import("./../../../src/pages/methods/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/methods/prototypetesting.mdx" /* webpackChunkName: "component---src-pages-methods-mdx-frontmatter-slug-js-content-file-path-methods-prototypetesting-mdx" */),
  "component---src-pages-methods-mdx-frontmatter-slug-js-content-file-path-methods-survey-mdx": () => import("./../../../src/pages/methods/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/methods/survey.mdx" /* webpackChunkName: "component---src-pages-methods-mdx-frontmatter-slug-js-content-file-path-methods-survey-mdx" */),
  "component---src-pages-methods-mdx-frontmatter-slug-js-content-file-path-methods-treetesting-mdx": () => import("./../../../src/pages/methods/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/methods/treetesting.mdx" /* webpackChunkName: "component---src-pages-methods-mdx-frontmatter-slug-js-content-file-path-methods-treetesting-mdx" */),
  "component---src-pages-methods-mdx-frontmatter-slug-js-content-file-path-methods-unmoderatedusabilitytesting-mdx": () => import("./../../../src/pages/methods/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/methods/unmoderatedusabilitytesting.mdx" /* webpackChunkName: "component---src-pages-methods-mdx-frontmatter-slug-js-content-file-path-methods-unmoderatedusabilitytesting-mdx" */),
  "component---src-pages-methods-mdx-frontmatter-slug-js-content-file-path-methods-usabilitytesting-mdx": () => import("./../../../src/pages/methods/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/methods/usabilitytesting.mdx" /* webpackChunkName: "component---src-pages-methods-mdx-frontmatter-slug-js-content-file-path-methods-usabilitytesting-mdx" */),
  "component---src-pages-methods-mdx-frontmatter-slug-js-content-file-path-methods-userinterview-mdx": () => import("./../../../src/pages/methods/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/methods/userinterview.mdx" /* webpackChunkName: "component---src-pages-methods-mdx-frontmatter-slug-js-content-file-path-methods-userinterview-mdx" */)
}

